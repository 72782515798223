<template>
  <div>
    <TableSanzioniUnaSoc
      @getSanzioniUnaSocietaList="getSanzioniUnaSocietaList"
      @resetFilters="resetFilters"
      :societa="societa"
      :totaleDisponibile="totaleDisponibile"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableSanzioniUnaSoc from "@/components/components-fit/società/sanzioni/TableSanzioniUnaSoc.vue";
import { getRiepilogoEconomato } from "@/requests/economato";
import { alertFailed } from "@/composables/swAlert";
export default defineComponent({
  name: "sanzioniSocieta",
  components: {
    TableSanzioniUnaSoc,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const rowsToSkip = computed(() => store.getters.rowsToSkipSanzioniUnaSoc);
    const fetchRows = computed(() => store.getters.fetchRowsSanzioniUnaSoc);
    const sortColumn = computed(() => store.getters.sortColumnSanzioniUnaSoc);
    const sortOrder = computed(() => store.getters.sortOrderSanzioniUnaSoc);
    const getSanzioniUnaSocietaList = async () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: null,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_SANZIONI_LIST,
        itemName: "societa_sanzioni_list",
      });
      //await getTotale();
    };
    const stagione_pagamento = computed(() => store.getters.stagioneSelected);
    watch(stagione_pagamento, () => {
      getTotale();
    });
    const resetFilters = async () => {
      store.commit("resetFiltersSanzioniUnaSoc");
      //await getTotale();
      await getSanzioniUnaSocietaList();
    };
    getSanzioniUnaSocietaList();
    const totaleDisponibile = ref(null);
    const getTotale = () => {
      totaleDisponibile.value = null;
      getRiepilogoEconomato(props.societa.id, stagione_pagamento.value).then(
        (res) => {
          if (res.status && res.status != 200)
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );

          totaleDisponibile.value = res.results[0].disponibile;
        }
      );
    };
    onMounted(async () => {
      //await getTotale();
      //await getSanzioniUnaSocietaList(); --commentato se no il seleziona tutto non andava
      setCurrentPageBreadcrumbs("Sanzioni Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    return {
      getSanzioniUnaSocietaList,
      resetFilters,
      totaleDisponibile,
    };
  },
});
</script>
